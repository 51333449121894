<template>
	<div>
		<CCard class="p-4"> 
			<CCardHeader>
				<CRow>
			
				<CCol lg="11">
				
						<h5 class="text-center text-uppercase">MY PROFILE</h5>
				</CCol>
				<CCol lg="1">
					<div style="text-align: right">
						<CButton color="btn btn-outline-danger" size="sm" @click="backToTable()">Close</CButton> 
						
					
					</div>
				</CCol>
					
				
			     	<!-- <CCol lg="6">
			      		<div style="text-align: right">
			      			<CButton color="success">DR's</CButton> &nbsp; 
			      			<CButton color="info" @click="showHistoryModal = true">History</CButton> &nbsp; 
	      					<CButton color="warning">Print</CButton> &nbsp; 
			      			<CButton color="danger" @click="backToTable()">Cancel</CButton> &nbsp; 
			      		</div>
					</CCol> -->
				</CRow>
			</CCardHeader>
			<CCardBody> 
				<CRow>
					<CCol lg="3">
					</CCol>
					<CCol lg="6">
						<CCard class="p-3 pl-3 pr-3 ml-3 mr-3 mt-2"> 
							<CRow class="ml-3 mr-3 mt-2"> 
								<CCol lg="12">
									<div style="height: 150px; width: 150px; background-color: #eee; margin-left: auto; margin-right: auto; border-radius: 100px"></div>
								</CCol> 
							</CRow> 
							<br/>
							<CRow class="ml-3 mr-3 mt-2"> 
								<CCol lg="12">
									<p class="text-center text-uppercase"> <strong>{{dataParams.firstname+" "+dataParams.lastname}}</strong></p>
								</CCol> 
							</CRow> 
							<CRow class="ml-3 mr-3 mt-2"> 
								<CCol lg="12">
									<p>Username: <strong>{{dataParams.username}}</strong></p>
								</CCol> 
							</CRow> 
							<CRow class="ml-3 mr-3 mt-2"> 
								<CCol lg="12">
									<p>Email address: <strong>{{dataParams.email}}</strong></p>
								</CCol> 
							</CRow> 
							<CRow class="ml-3 mr-3 mt-2"> 
								<CCol lg="12">
									<p>Usertype: <strong>{{dataParams.usertype.setting_name}}</strong></p>
								</CCol> 
							</CRow> 
							<CRow class="ml-3 mr-3 mt-2"> 
								<CCol lg="12">
									<p>Depot: <strong>{{dataParams.depot ? dataParams.depot.setting_name : "N/A"}}</strong></p>
								</CCol> 
							</CRow> 
							<CRow class="ml-3 mr-3 mt-2"> 
								<CCol lg="12">
									<p>Status: <strong class="text-uppercase">{{dataParams.status}}</strong></p>
								</CCol> 
							</CRow>  
							<br/> 
							<CRow class="ml-3 mr-3 mt-2"> 
								<CCol lg="6">
									<CButton to="/account/update_profile" color="info" style="width: 100%">Update Profile</CButton>
								</CCol> 
								<CCol lg="6">
									<CButton color="success" style="width: 100%" @click="modalState = true">Change Password</CButton>
								</CCol> 
							</CRow> 
							  
						</CCard>
					</CCol>
				</CRow>
				
			</CCardBody> 
		</CCard>
		<!-- <HistoryModal :details="dataParams" @showModal="showHistoryModal = $event" :showModal="showHistoryModal" /> -->
		<CModal title="Update Password" :show.sync="modalState" color="info" size="md"> 
			<form @submit.prevent="updatePassword">
	    	<div class="pl-3 pr-3 pb-3"> 
	    		<CRow class="mb-2">
	        		<CCol lg="12">
	        			<label>Current Password<span class="text-danger">*</span></label>
		        		<CInput 
							placeholder="Current Password"
							required
							v-model="current_password"  
							type="password"
		                />
	        		</CCol> 
	        	</CRow> 
	        	<CRow class="mb-2">
	        		<CCol lg="12">
	        			<label>New Password<span class="text-danger">*</span></label>
		        		<CInput 
							placeholder="New Password"
							required
							v-model="password"
							type="password"  
		                />
	        		</CCol> 
	        	</CRow> 
	        	<CRow class="mb-2">
	        		<CCol lg="12">
	        			<label>Confirm Password<span class="text-danger">*</span></label>
		        		<CInput 
							placeholder="Confirm Password"
							required
							v-model="confirm_password"  
							type="password"
		                />
	        		</CCol> 
	        	</CRow>
	        	<span class="text-danger">{{message}}</span>
	    	</div>
	    	<div slot="footer" class="w-100">
	    		<CButton size="sm" style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="modalState = false">
	                Close
	            </CButton> 
	    		<CButton size="sm" style="border-radius: .2rem; color: white;" color="success" class="ml-1 mr-1 float-right" type="submit">
	                Save
	            </CButton> 
	    		 
	        </div>
	        </form>
	    	<div slot="footer" class="w-100"></div>
	    </CModal>
	</div>
</template> 

<script>
import axios from '../../axios';
import config from '../../config.js';
import Swal from 'sweetalert2'; 

export default{
	
	data(){
		return{
			dataParams: {
				username: "",
				email: "",
				usertype: {
					setting_name: ""
				},
				depot: {
					setting_name: ""
				},
				status: "",
				firstname: "",
				lastname: ""
			},
			current_password: "",
			password: "",
			confirm_password: "",
			modalState: false,
			message: ""
		}
	},
	mounted(){
		this.getUser();
	},
	methods: {
		backToTable(){
	    	this.$router.push('/main/booking')
	    },
		getUser(){
			var user = window.localStorage.getItem('user_data');
			user = JSON.parse(user);
			axios.get(config.api_path+"/users/"+user.id)
			.then(response=>{
				this.dataParams = response.data.data
			})
		},
		updatePassword(){
			var user = window.localStorage.getItem('user_data');
			user = JSON.parse(user);
			this.message = "Verifying...";
			if(this.password != this.confirm_password){
				this.message = "New password and confirm password doesn't match.";
				return;
			}
			axios.put(config.api_path+"/users/"+user.id, {password: this.password, current_password: this.current_password})
			.then(response=>{
				if(response.data.status == "failed"){
					this.message = response.data.message;
				}
				else{
					Swal.fire({
						title: 'Success!',
						text: 'Successfully updated password',
						icon: 'success', 
					})
					this.modalState = false;
				}
			})
		}
	}

}
</script>